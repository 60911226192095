
$(document).ready(function () {
    document.getElementsByTagName("iframe")[0].className = "fullScreen";
    
    $('.owl-carousel').owlCarousel(settings);

    $('.mobile-menu').click(function () {
        $(this).toggleClass('open');
        $('.nav-buttons').toggleClass('show');
        $('body').toggleClass('lock');
    });

    $('.nav-buttons .button a').click(function (e) {
        e.preventDefault();
        
        var href = $(this).attr('href');

        $('.mobile-menu').removeClass('open');
        $('.nav-buttons').removeClass('show');
        $('body').removeClass('lock');

        if ($(this).attr('target') == '_blank') {
            window.open(href, '_blank');
        } else {
            $('html, body').animate({
                scrollTop: $(href).offset().top
            }, 200);
        }
    });
});