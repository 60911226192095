/* TABS */

// usage
/*
    <div class="Tabs outer">
        <div class="nav horizontal fa">
            <div class="tab"><h3></h3></div>
            <div class="tab active"><h3></h3></div>
        </div>
        <div class="content">
            <div class="tab">
                <section>
                    <div class="wrapper float slim">
                        <div class="Tabs inner">...</div>
                    </div>
                </section>
            </div>
        </div>
    </div>
*/

$.fn.extend({
  toggleText: function (a, b) {
    return this.text(this.text() == b ? a : b);
  }
});

var doc = $('html');

function goDesktop() {
  doc.attr('id', 'isDesktop');
}

function goLandscape() {
  doc.attr('id', 'isLandscape');
}

function goTablet() {
  doc.attr('id', 'isTablet');
}

function goPortrait() {
  doc.attr('id', 'isPortrait');
}

if ($(window).width() < $(window).height() && $(window).width() > 420) {
  goTablet();
} else if ($(window).width() < $(window).height() && $(window).width() < 420) {
  goPortrait();
} else if ($(window).width() > $(window).height() && $(window).width() < 1203 && $(window).height() < 440) {
  goLandscape();
} else if ($(window).width() > 1023 && $(window).width() > $(window).height()) {
  goDesktop();
}

$(window).resize(function () {
  if ($(window).width() < $(window).height() && $(window).width() > 420) {
    goTablet();
  } else if ($(window).width() < $(window).height() && $(window).width() < 420) {
    goPortrait();
  } else if ($(window).width() > $(window).height() && $(window).width() < 1203 && $(window).height() < 440) {
    goLandscape();
  } else if ($(window).width() > 1023 && $(window).width() > $(window).height()) {
    goDesktop();
  }
});

// sets up Tabs
var Tabs = $('.contents-holder');
var T = 0;
var s = 0;
var n = 0;
var c = 0;
var nc = 0;
var cc = 0;

// adds index to corresponding sections for each Tab
Tabs.each(function () {
  var section = $(this).closest('section');
  var nav = $(this).find('.inner-contents-trigger');
  var content = $(this).find('.inner-contents-details');

  $(this).attr('tab-index', T++);
  section.attr('section-index', s++);
  nav.attr('nav-index', n++);
  content.attr('content-index', c++);

  // adds matching data-index to each Tab section for navigation
  nav.children().each(function () {
    $(this).attr("data-index", nc++);
  });
  content.children().each(function () {
    $(this).attr("data-index", cc++);
  });
});

// Tabs navigation
$('.contents-holder .inner-contents-trigger .desktop-button a').click(function (event) {
  event.preventDefault();
  event.stopPropagation();
  
  var button = $(this).find('span');
  
  // finds the nav-index of selected item group and adds active class
  var aN = $(this).closest('.inner-contents-trigger').attr('nav-index');
  var activeTriggerHolder = $('.contents-holder .inner-contents-trigger[nav-index="' + aN + '"]');
  var activeDetailHolder = $('.contents-holder .inner-contents-details[content-index="' + aN + '"]');

  $('.contents-holder .inner-contents-trigger').not(activeTriggerHolder).removeClass('active');
  $('.contents-holder .inner-contents-details').not(activeDetailHolder).removeClass('active');
  activeTriggerHolder.addClass('active');
  activeDetailHolder.addClass('active');

  //finds the active item in the active item group and adds active class to show all details
  var activeTrigger = $(this).closest('.inner-contents');
  var activeIndex = activeTrigger.data('index');
  var activeDetail = activeDetailHolder.find('.inner-contents[data-index="'+ activeIndex +'"]');
  $('.contents-holder .inner-contents-trigger .inner-contents').not(activeTrigger).removeClass('active');
  $('.contents-holder .inner-contents-details .inner-contents').not(activeDetail).slideUp(500);
  activeTrigger.toggleClass('active');
  activeDetail.slideToggle(500).toggleClass('active');
  
  if (button.text() === "VIEW MORE") {
    $('.contents-holder .inner-contents-trigger .desktop-button a > span').not(button).text('VIEW MORE');
    button.text('VIEW LESS');
  } else {
    button.text('VIEW MORE')
  }
  
});


$('.contents-holder .inner-contents-trigger .mobile-button a').click(function (event) {
  event.preventDefault();
  event.stopPropagation();

  var aN = $(this).closest('.inner-contents-trigger').attr('nav-index');
  var activeDetailHolder = $('.contents-holder .inner-contents-trigger[nav-index="' + aN + '"]');
  var aDi = $(this).closest('.inner-contents').data('index');
  var activeDetail = $('.inner-contents[data-index="' + aDi + '"]');

  $('.contents-holder .inner-contents-trigger').not(activeDetailHolder).removeClass('active');
  $('.inner-contents').not(activeDetail).removeClass('active');
  activeDetailHolder.addClass('active');
  activeDetail.toggleClass('active');
});