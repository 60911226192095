var settings = {
  items: 1,
  loop: true,
  center: true,
  nav: true,
  dots: true,
  lazyload: true,
  autoHeight:true
}

// $('.view-more a').click(function(event){
//   event.preventDefault();
//   var button = $(this).find('span');
//   $(this).parent().parent().toggleClass('active');
//   if (button.text() === "VIEW MORE") {
//     $('.contents-holder .inner-contents-trigger .desktop-button a > span').not(button).text('VIEW MORE');
//     button.text('VIEW LESS');
//   } else {
//     button.text('VIEW MORE')
//   }
// });

// $('.owl-carousel').on('changed.owl.carousel', function (event) {
//   $('.owl-item .overlay').removeClass('active');
// });

